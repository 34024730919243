* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

#root {
  position: relative;
  height: 100%;
  z-index: 1;
}

#modal-root {
  position: fixed;
  z-index: 2;
}

#toasts-root {
  position: fixed;
  width: 100%;
  z-index: 3;
}

textarea,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="password"] {
  font-size: 1em;
}

a, button, input, select, textarea, div {
  /* Remove mobile highlight */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

button, input, select, textarea {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font: inherit;
  resize: none;
}

button:-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  cursor: pointer;
}
